<template>
  <v-dialog
    :value="true"
    transition="fade-transition"
    fullscreen
  >
    <div>
      <v-card
        :key="clickedDayData.date"
        v-on-clickaway="closeDetailsCard"
        :style="positionDetailModal"
        height="100%"
      >
        <v-card-title>
          <v-row>
            <v-col>
              <v-row class="d-flex align-center justify-space-between px-1">
                <div class="h6 text-no-wrap d-inline-block text-truncate" style="max-width: 18rem;" v-text="cardData.title" />
                <v-btn icon color="black" @click="$emit('closeDetailCard')">
                  <v-icon small color="primary">
                    close
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text style="height: 10rem;">
          <v-row class="px-1" style="height: 100%;">
            <v-col class="d-flex flex-column justify-space-between">
              <v-row class="d-flex align-center justify-space-between">
                <div>
                  <v-icon small class="mr-2">
                    calendar_today
                  </v-icon>
                  <span v-text="cardData.date" />
                </div>
                <div class="mr-3">
                  <v-icon small class="mr-2">
                    access_time
                  </v-icon>
                  <span v-text="cardData.time" />
                </div>
              </v-row>
              <v-row class="d-flex align-center">
                <span
                  v-if="cardData.type !== 'guildMatch'"
                  :style="{ color: colors[cardData.type] }"
                  class="text-capitalize subtitle-2"
                  v-text="cardData.type && $t(`project.academy.calendar.${cardData.type}`)"
                />
                <span v-if="cardData.coaches" class="mx-2" v-text="' - '" />
                <span v-for="(coach, i) in cardData.coaches" :key="i" class="caption mr-1" v-text="coach" />
              </v-row>
              <v-row class="d-flex align-center">
                <span v-if="cardData.parentsOnly" class="font-weight-medium" v-text="$t('project.academy.calendar.parentsOnly')" />
                <span
                  class="d-inline-block text-truncate"
                  style="max-height: 2rem; overflow-y: hidden;"
                  :style="{width: cardData.parentsOnly ? '16rem' : '22rem'}"
                  v-html="cardData.footer"
                />
              </v-row>
              <v-row v-if="cardData.type === 'guildMatch'" class="d-flex justify-space-between mt-4">
                <div
                  class="d-flex align-center" style="cursor: pointer;"
                  @click="$router.push({ name: 'event-edit-guild-match', params: { guildMatchActivityId: cardData.id }})"
                >
                  <div style="width: 16px">
                    <v-icon class="d-block" color="primary" small>
                      edit_outline
                    </v-icon>
                  </div>
                  <span class="caption ml-2 primary--text" v-text="$t('common.edit')" />
                </div>
                <div
                  class="d-flex align-center" style="cursor: pointer;"
                  @click="$router.push({ name: 'tickets-edit-guild-match', params: { guildMatchActivityId: cardData.id }})"
                >
                  <div>
                    <v-icon class="d-block" color="primary" small>
                      confirmation_number
                    </v-icon>
                  </div>
                  <span class="caption ml-2 primary--text" v-text="$t('cluster.tickets.assignTickets.assignTickets')" />
                </div>
              </v-row>
              <v-row
                v-else
                class="d-flex align-center"
                style="cursor: pointer;"
                @click.stop="$emit('redirectToEvent', { event: cardData })"
              >
                <v-icon color="primary" small>
                  remove_red_eye
                </v-icon>
                <span class="caption ml-2 primary--text" v-text="$t(`project.academy.calendar.seeDetails`)" />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'CalendarEventCard',
  mixins: [clickaway],
  props: {
    clickedDayData: {
      type: Object,
      default: () => {},
    },
    positionDetailModal: {
      type: Object,
      default: () => {},
    },
    cardData: {
      type: Object,
      default: () => {},
    },
    colors: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeDetailsCard() {
      this.$emit('closeDetailCard')
    },
  },
}
</script>
